import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`*`}{`Today is our Free Halloween workout at 9:00 & 10:00am.  Come
dressed IN COSTUME! Best costume wins a prize!`}</em></strong></p>
    <p>{`We’ll be doing our traditional Halloween WOD: Alive & Zombies`}</p>
    <p>{`1 Lap Run`}</p>
    <p>{`90 Single or Double Unders`}</p>
    <p>{`75 Situps`}</p>
    <p>{`60 KBS (53/35)`}</p>
    <p>{`45 Pushups`}</p>
    <p>{`30 Ring Rows`}</p>
    <p>{`15 Calorie Row`}</p>
    <p><em parentName="p">{`*`}{`Alive group starts the wod 3:00 before the Zombie group.  If any
Zombie catches (passes) and Alive there is a 25 burpee penalty at the
end of the wod.  If a Zombie doe not catch anyone there is also a 25
burpee penalty.`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We are in need for Judges/Volunteers for our Granite Games Winter
Throwdown on December 7th.  If you aren’t competing please consider
helping out!  We’ll get you a shirt (if you volunteer by Nov 3rd), and
feed you throughout the day.  Sign up on competition corner.net and be
sure to put in your shirt size.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      